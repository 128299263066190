@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url('./assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: 'normal';
  font-display: 'swap';
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Medium'), url('./assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: 'normal';
  font-display: 'swap';
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-SemiBold'), url('./assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: 'normal';
  font-display: 'swap';
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Bold'), url('./assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: 'normal';
  font-display: 'swap';
}

#root {
  min-height: 100vh;
  min-height: 100dvh;
  height: 100%;
  background-color: var(--mantine-color-body);
}

body,
input,
select,
textarea,
button {
  /* font-family: 'Raleway', sans-serif; */
  font-variant-numeric: lining-nums;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
